import { useState } from 'react';
import betaImage from '../images/beta/1.6 Iphone_komplett.webp';

export default function BetaVersion() {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add email sending logic here (e.g., API call)
    // Assuming email is sent successfully:
    setSubmitted(true);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setIsLoading(true);
  //   setError(null);

  //   try {
  //     const response = await fetch('https://your-api-gateway-url.amazonaws.com/beta-signup', {
  //       method: 'POST',
  //       body: JSON.stringify({ email }),
  //       headers: { 'Content-Type': 'application/json' },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to submit email');
  //     }

  //     setSubmitted(true);
  //   } catch (err) {
  //     setError('An error occurred. Please try again.');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div className="beta-container">
      <div className="beta-image-container">
        <img loading="lazy" src={betaImage} alt="Mobile App" />
      </div>
      <div className="beta-subscripe-container">
        <span>Anmelden</span>
        <h2>Beta-Version</h2>
        <p>Bald starten wir durch! Melde dich jetzt an und sichere dir exklusiven Zugang zur Beta-Version von Stu!</p>
        <div className="subscripe-container">
          {submitted ? (
            <h4 className="thank-you-message">Vielen Dank!</h4>
          ) : (
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                id='beta-email'
                placeholder="E-Mail-Adresse"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit">Anmelden</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
