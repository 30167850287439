import { Link } from "react-scroll";
import { NavLink } from "react-router-dom"

const NavBar = ({ onToggleMenu }) => {

  const handleClick = () => {
    onToggleMenu(true);
  };

  return (
    <>
    <header className="main-header">
      <nav role='navigation' className='navigation-bar'>
        <NavLink to="/" className='navigation-links'>
          <svg xmlns="http://www.w3.org/2000/svg" width="67" height="23" viewBox="0 0 67 23" fill="none">
            <path d="M11.7465 11.6471L10.0371 11.5044C5.80752 11.1641 4.38303 10.725 4.38303 9.76994C4.38303 8.8149 6.18008 8.28799 9.88374 8.28799C13.7956 8.28799 15.3735 8.80393 15.7022 10.1871L15.757 10.4066H20.0524L20.0085 10.0993C19.5812 6.45475 16.3158 4.68738 10.0371 4.68738C3.75845 4.68738 0.536922 6.42182 0.536922 9.82483C0.536922 12.9424 3.11195 14.4463 9.44544 15.0172L10.9795 15.1599C15.1763 15.5221 16.7322 16.071 16.7322 17.1688C16.7322 18.3653 14.8147 18.98 11.0233 18.98C6.72796 18.98 4.53644 18.1787 4.32824 16.5211L4.29537 16.2796H0V16.576C0.197236 20.5608 3.79132 22.5806 10.6946 22.5806C17.5979 22.5806 20.5893 20.6925 20.5893 16.9712C20.5893 13.656 18.1129 12.163 11.7465 11.6471Z" fill="white"/>
            <path d="M52.3882 5.23625V12.3496C52.3882 16.3345 49.7693 18.8044 45.5616 18.8044C41.6059 18.8044 40.0609 17.037 40.0609 12.4924V5.23625H26.7803V0L22.8246 1.66857V16.6638C22.8246 20.8023 24.8189 22.5697 29.4978 22.5697C31.1305 22.5697 32.6974 22.2952 34.0233 22.0208L34.2424 21.9769V18.4531L33.9137 18.519C32.1934 18.8703 31.4263 19.002 30.1991 19.002C27.9309 19.002 26.7803 18.4641 26.7803 15.5112V8.52949H38.0666L35.9847 10.1102V13.7767C35.9847 19.7814 38.5269 22.5697 43.9728 22.5697C47.4902 22.5697 50.383 21.3512 52.3882 19.0239V22.0208H56.4644V5.23625H52.3882Z" fill="white"/>
            <path d="M63.3972 10.7332C65.1732 10.7332 66.6129 9.35464 66.6129 7.65406C66.6129 5.95348 65.1732 4.57488 63.3972 4.57488C61.6212 4.57488 60.1815 5.95348 60.1815 7.65406C60.1815 9.35464 61.6212 10.7332 63.3972 10.7332Z" fill="#FF003E"/>
          </svg>  
        </NavLink>
        <Link to="produkt" smooth={true} duration={1000} className="navigation-links">
          Produkt
        </Link>
        <Link to="about" smooth={true} duration={1000} className="navigation-links">
          Über Uns
        </Link>
        <Link to="questions" smooth={true} duration={2500} className="navigation-links">
          FAQ
        </Link>
        <NavLink to="/kontakt" className='navigation-links' target="_blank">Kontakt</NavLink>
      </nav>
    </header>
    <header className="tablet-header">
      <NavLink to="/" >
        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="23" viewBox="0 0 67 23" fill="none">
          <path d="M11.7465 11.6471L10.0371 11.5044C5.80752 11.1641 4.38303 10.725 4.38303 9.76994C4.38303 8.8149 6.18008 8.28799 9.88374 8.28799C13.7956 8.28799 15.3735 8.80393 15.7022 10.1871L15.757 10.4066H20.0524L20.0085 10.0993C19.5812 6.45475 16.3158 4.68738 10.0371 4.68738C3.75845 4.68738 0.536922 6.42182 0.536922 9.82483C0.536922 12.9424 3.11195 14.4463 9.44544 15.0172L10.9795 15.1599C15.1763 15.5221 16.7322 16.071 16.7322 17.1688C16.7322 18.3653 14.8147 18.98 11.0233 18.98C6.72796 18.98 4.53644 18.1787 4.32824 16.5211L4.29537 16.2796H0V16.576C0.197236 20.5608 3.79132 22.5806 10.6946 22.5806C17.5979 22.5806 20.5893 20.6925 20.5893 16.9712C20.5893 13.656 18.1129 12.163 11.7465 11.6471Z" fill="white"/>
          <path d="M52.3882 5.23625V12.3496C52.3882 16.3345 49.7693 18.8044 45.5616 18.8044C41.6059 18.8044 40.0609 17.037 40.0609 12.4924V5.23625H26.7803V0L22.8246 1.66857V16.6638C22.8246 20.8023 24.8189 22.5697 29.4978 22.5697C31.1305 22.5697 32.6974 22.2952 34.0233 22.0208L34.2424 21.9769V18.4531L33.9137 18.519C32.1934 18.8703 31.4263 19.002 30.1991 19.002C27.9309 19.002 26.7803 18.4641 26.7803 15.5112V8.52949H38.0666L35.9847 10.1102V13.7767C35.9847 19.7814 38.5269 22.5697 43.9728 22.5697C47.4902 22.5697 50.383 21.3512 52.3882 19.0239V22.0208H56.4644V5.23625H52.3882Z" fill="white"/>
          <path d="M63.3972 10.7332C65.1732 10.7332 66.6129 9.35464 66.6129 7.65406C66.6129 5.95348 65.1732 4.57488 63.3972 4.57488C61.6212 4.57488 60.1815 5.95348 60.1815 7.65406C60.1815 9.35464 61.6212 10.7332 63.3972 10.7332Z" fill="#FF003E"/>
        </svg>
      </NavLink>
      <button className="" onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 30 15" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M29.6448 3.2H0.856934V0.896973H29.6448V3.2Z" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M29.6448 14.7152H0.856934V12.4121H29.6448V14.7152Z" fill="white"/>
        </svg>
      </button>
    </header>
   </>
  );
};

export default NavBar;