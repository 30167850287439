import { NavLink } from "react-router-dom";

const Footer = () => {

  return (
    <footer className='footer'>
        <div className="footer-line"></div>
        <div className="footer-details">
            <NavLink to="/kontakt" className='footer-links' target="_blank">Kontakt</NavLink>
            <NavLink to="/" className='footer-links'>Impressum</NavLink>
            <NavLink to="/datenschutz" className='footer-links' target="_blank">Datenschutz</NavLink>
        </div>
    </footer>
  );
};

export default Footer;
