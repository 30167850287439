export const Datenschutz = () => {

      return (
        <div className="datenschutz-container">
            <h1>Datenschutzerklärung</h1>
            <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
            <p>Mark Darwich</p>
            <h2>Ihre Betroffenenrechte</h2>
            <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
            <ul>
                <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO)</li>
                <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO)</li>
                <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO)</li>
                <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO)</li>
                <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)</li>
                <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
            </ul>
            <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
            <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
            <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a target="_blank" rel="noreferrer" href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a></p>
            <h2>Drittlandtransfer:</h2>
            <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:</p>
            <p>Nein</p>
            <p>Folgende Datenschutzgarantien liegen vor:</p>
            <h2>Newsletter</h2>
            <h2>Art und Zweck der Verarbeitung:</h2>
            <p>Für die Zustellung unseres Newsletters erheben wir personenbezogene Daten, die über eine Eingabemaske an uns übermittelt werden.</p>
            <p>Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das „Double-Opt-in“-Verfahren ein. Hierzu protokollieren wir die Anmeldung zum Newsletter, den Versand einer Bestätigungsmail und den Eingang der hiermit angeforderten Antwort. Weitere Daten werden nicht erhoben.</p>
            <h2>Rechtsgrundlage:</h2>
            <p>Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), übersenden wir Ihnen regelmäßig unseren Newsletter bzw. vergleichbare Informationen per E-Mail an Ihre angegebene E-Mail-Adresse.</p>
            <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem können Sie sich jederzeit auch direkt auf dieser Website abmelden oder uns Ihren Widerruf über die am Ende dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.</p>
            <h2>Empfänger:</h2>
            <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
            <h2>Drittlandtransfer:</h2>
            <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:</p>
            <p>Nein</p>
            <p>Folgende Datenschutzgarantien liegen vor:</p>
            <h2>Speicherdauer:</h2>
            <p>Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gelöscht.</p>
            <h2>Bereitstellung vorgeschrieben oder erforderlich:</h2>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung können wir Ihnen unseren Newsletter leider nicht zusenden.</p>
            <h2>Widerruf der Einwilligung:</h2>
            <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Die Abmeldung kann über den in jeder E-Mail enthaltenen Link oder beim unten aufgeführten Datenschutzbeauftragten bzw. der für den Datenschutz zuständigen Person beantragt werden.</p>
            <h2>Kontaktformular</h2>
            <h2>Art und Zweck der Verarbeitung:</h2>
            <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
            <h2>Rechtsgrundlage:</h2>
            <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
            <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
            <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
            <h2>Empfänger:</h2>
            <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
            <h2>Drittlandtransfer:</h2>
            <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:</p>
            <p>Nein</p>
            <p>Folgende Datenschutzgarantien liegen vor:</p>
            <h2>Speicherdauer:</h2>
            <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
            <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen.</p>
            <h2>Bereitstellung vorgeschrieben oder erforderlich:</h2>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
            <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
            <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</p>
            <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a rel="noreferrer" target="_blank" href='https://developers.google.com/fonts/faq'>https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google:  <a rel="noreferrer" target="_blank" href="https://www.google.com/policies/privacy/"> https://www.google.com/policies/privacy/</a>.</p>
            <h2>SSL-Verschlüsselung</h2>
            <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
            <hr />
            <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
            <h2>Einzelfallbezogenes Widerspruchsrecht</h2>
            <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
            <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
            <h2>Empfänger eines Widerspruchs</h2>
            <hr /> 
            <h2>Änderung unserer Datenschutzbestimmungen</h2>
            <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
            <h2>Fragen an den Datenschutzbeauftragten</h2>
            <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
            <p>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a rel="noreferrer" target="_blank" href='https://www.activemind.de/datenschutz/datenschutzbeauftragter/'>externe Datenschutzbeauftragte</a> (Version #2020-09-30).</p>
        </div>
      );
  };
  
export default Datenschutz;

  