import image1 from '../images/carousel-images/1.webp';
import image2 from '../images/carousel-images/2.webp';
import image3 from '../images/carousel-images/3.webp';
import image4 from '../images/carousel-images/4.webp';
import image5 from '../images/carousel-images/5.webp';
import image6 from '../images/carousel-images/6.webp';
import image7 from '../images/carousel-images/7.webp';
import image8 from '../images/carousel-images/8.webp';

const images = [
  {src: image1, key: 1, studios: "Musik Studios"},
  {src: image2, key: 2, studios: "Locations"},
  {src: image3, key: 3, studios: "Videostudios"},
  {src: image4, key: 4, studios: "Fotostudios"},
  {src: image5, key: 5, studios: "Green Screen Studios"},
  {src: image6, key: 6, studios: "Band Studios"},
  {src: image7, key: 7, studios: "Podcast Studios"},
  {src: image8, key: 8, studios: "Home Studios"},
];


const ImagesCarouselAnimation = () => {

  return (
    <div className="images-carousel-container">
      {images.map((image, index) => (
        <div key={image.key} className="image-wrapper">
          <img src={image.src} alt={`${index + 1}`}/>
          <span>{image.studios}</span>
        </div>
      ))}
    </div>
  );
};

export default ImagesCarouselAnimation;