import { useState } from 'react';
import { NavLink } from "react-router-dom"

const QuestionsAnswers = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <div id="questions" className='questions-answers-container'>
      <div className='questions-answers'>
        <h1>Fragen?</h1>
        <h1>Antworten.</h1>
      </div>
      <div className="faq-container">
        {faqItems.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <span>{item.question}</span>
              <div className="faq-toggle">
                {activeIndex === index ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="1" viewBox="0 0 13 1" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.273 0.876642H0V0H12.273V0.876642Z" fill="#1F1F1F"/>
                  </svg> 
                  : 
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.24512 6.86588V12.564H6.36847V6.86588H0.669922V5.98923H6.36847V0.291016H7.24512V5.98923H12.9429V6.86588H7.24512Z" fill="#101010"/>
                  </svg> 
                }
              </div>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              {item.answer}
            </div>
          </div>
        ))}
        <div className='more-questions'>
          <h4>Noch weitere Fragen?</h4>
          <div>
            <h4>Schreib uns</h4><NavLink to="/kontakt" target="_blank">hier.</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const faqItems = [
  {
    question: "Was ist Stu?",
    answer: "Stu ist eine Plattform, die Kreative mit Studios verbindet. Hier können Studios gemietet und vermietet werden, um kreative Projekte zu realisieren."
  },
  {
    question: "Welche Kosten entstehen für Nutzer und Anbieter?",
    answer: "Die Nutzung von Stu ist für Nutzer und Anbieter kostenfrei. Kosten entstehen lediglich aus Provisionsgebühren bei erfolgreichen Buchungen. Anbieter haben die Freiheit, die Preise, Rabatte und Aktionen für ihre Studios individuell zu gestalten, ebenso wie Produzenten, Fotografen und andere."
  },
  {
    question: "Wie funktioniert die Buchung von Produzenten, Fotografen & Videografen?",
    answer: "Mit der ersten Version von stu erhalten Produzenten, Fotografen und Videografen die Möglichkeit sich ein eigenes Profil, Referenzen und Kalender einzurichten und diese auf Studioseiten zu platzieren."
  },
  {
    question: "Kann ich als Nutzer einen Produzenten oder Fotografen unabhängig von einem Studio buchen?",
    answer: "In den weiteren Updates, haben Produzenten und Fotografen separate Profile, die unabhängig vom Studio buchbar sind."
  },
  {
    question: "Wie unterstützt Stu Anbieter bei der Verwaltung?",
    answer: "Anbieter haben Zugriff auf Einnahme-Daten, Tipps, Tricks und einen Support-Service. Der integrierte Kalender ermöglicht eine einfache Koordination von Ressourcen und dient dem Anbieter als Planungstool."
  },
  {
    question: "Wie schützt Stu Studios vor möglichen Schäden?",
    answer: "Studios sind über Stu vor möglichen Schäden versichert. Darüber hinaus profitieren Anbieter und Nutzer von klaren Buchungsregeln und transparenten Bewertungen."
  }
];

export default QuestionsAnswers;
