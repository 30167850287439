// Kontakt.js
import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';

export const Kontakt = () => {
  // Verwende die useForm Hook von Formspree
  const [state, handleSubmit] = useForm("mgvwgarw"); // Ersetze "mgvwgarw" mit deiner tatsächlichen Form-ID

  // State für die Formularfelder
  const [formData, setFormData] = useState({
    name: '',
    role: '',
    email: '',
    phone: '',
    message: '',
    consent: false
  });

  // State für lokale Validierungsfehler
  const [errors, setErrors] = useState({});

  // State für allgemeine Fehler (z.B. Netzwerkausfälle)
  const [error, setError] = useState(null);

  // Handler für Änderungen an den Formularfeldern
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: val });

    // Lösche bestehende Fehler für das aktuelle Feld
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  // Funktion zur lokalen Formularvalidierung
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name ist erforderlich';
    if (!formData.role.trim()) newErrors.role = 'Rolle ist erforderlich';
    if (!formData.email.trim()) newErrors.email = 'E-Mail ist erforderlich';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Ungültige E-Mail-Adresse';
    // Wenn das Telefonfeld optional sein soll, entferne die folgende Zeile:
    // if (!formData.phone.trim()) newErrors.phone = 'Telefon ist erforderlich';
    if (!formData.message.trim()) newErrors.message = 'Nachricht ist erforderlich';
    if (!formData.consent) newErrors.consent = 'Zustimmung ist erforderlich';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handler für das Absenden des Formulars
  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Setze vorherige Fehler zurück

    if (validateForm()) {
      try {
        await handleSubmit(e);
      } catch (err) {
        setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
      }
    }
  };

  // Anzeige der Dankeschön-Nachricht nach erfolgreichem Absenden
  if (state.succeeded) {
    return (
      <div className="contact-container">
        <h1>Vielen Dank für Ihre Nachricht!</h1>
        <p>Wir werden uns so bald wie möglich bei Ihnen melden.</p>
      </div>
    );
  }

  return (
    <div className="contact-container">
      <h1>Kontakt</h1>
      <div className='contact-details'>
        <h2>E-Mail</h2>
        <span>info@stu-studios.de</span>
        <h2>Telefon</h2>
        <span>+49 176 20291843</span>
      </div>
      <h1>Schreib uns gerne.</h1>
      <form 
        className="contact-form" 
        onSubmit={onSubmit} 
        action="https://formspree.io/f/mgvwgarw" 
        method="POST"
      >
        {/* Name */}
        <div className='contact-form-box'>
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-input"
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}
            <ValidationError 
              prefix="Name" 
              field="name"
              errors={state.errors}
            />
          </div>
          
          {/* Rolle */}
          <div>
            <label htmlFor="role">Deine Rolle</label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              className="form-input"
              required
            >
              <option value="">Bitte wählen...</option>
              <option value="TonStudio">Tonstudio/Musikstudio Host</option>
              <option value="Fotostudio">Fotostudio Host</option>
              <option value="Videostudio">Videostudio Host</option>
              <option value="MieterGast">Mieter/Gast</option>
              <option value="Produzent">Produzent</option>
              <option value="Fotograf">Fotograf</option>
              <option value="Videograf">Videograf</option>
            </select>
            {errors.role && <span className="error">{errors.role}</span>}
            <ValidationError 
              prefix="Rolle" 
              field="role"
              errors={state.errors}
            />
          </div>
        </div>

        {/* E-Mail und Telefon */}
        <div className='contact-form-box'>
          <div>
            <label htmlFor="email">E-Mail Adresse</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-input"
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
            <ValidationError 
              prefix="E-Mail" 
              field="email"
              errors={state.errors}
            />
          </div>
          <div>
            <label htmlFor="phone">Telefonnummer (optional)</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="form-input"
              // Entferne 'required', wenn das Feld optional sein soll
              // required 
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
            <ValidationError 
              prefix="Telefon" 
              field="phone"
              errors={state.errors}
            />
          </div>
        </div>

        {/* Nachricht */}
        <div className='contact-form-box'>
          <div>
            <label htmlFor="message">Wie können wir dir helfen?</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="form-input"
              required
            ></textarea>
            {errors.message && <span className="error">{errors.message}</span>}
            <ValidationError 
              prefix="Nachricht" 
              field="message"
              errors={state.errors}
            />
          </div>
        </div>

        {/* Zustimmung */}
        <div className="consent">
          <input
            type="checkbox"
            id="consent"
            name="consent"
            checked={formData.consent}
            onChange={handleChange}
            className="checkbox-input"
            required
          />
          <label htmlFor="consent">
            Ich bin damit einverstanden, dass diese Website meine personenbezogenen Daten speichert und verarbeitet.
          </label>
          {errors.consent && <span className="error">{errors.consent}</span>}
          <ValidationError 
            prefix="Zustimmung" 
            field="consent"
            errors={state.errors}
          />
        </div>

        {/* Absende-Button */}
        <button type="submit" className="submit-button" disabled={state.submitting}>
          {state.submitting ? 'Wird gesendet...' : 'Senden'}
        </button>

        {/* Fehlernachricht von Formspree */}
        {state.errors && state.errors.length > 0 && (
          <div className="error-message">
            <p>Es gab ein Problem bei der Formularübertragung. Bitte versuchen Sie es erneut.</p>
          </div>
        )}

        {/* Lokale Fehlernachricht */}
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default Kontakt;